import styled from '@emotion/styled';
import { Col } from 'antd';

import { theme } from 'antd';

const { getDesignToken } = theme;

const globalToken = getDesignToken();

const { sizeMS } = globalToken;
export const RightSideBarContentWrapper = styled(Col)(() => ({
  paddingBlock: sizeMS,
  overflow: 'hidden',
  position: 'sticky',
  '& .RightSideBarContentWrapper___inner': {
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
}));
