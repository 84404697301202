import { IconCliveLogo, NavBar } from '@djob/react-components';
import { AvatarProps, ButtonProps, Image, theme } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectAccount } from 'state/accountSlice';
import { t } from 'i18next';

export const AntHeader = memo(() => {
  const { useToken } = theme;
  const { sizeMS } = useToken().token;
  const { account } = useSelector(selectAccount);
  const { picture } = account;

  const items: ItemType[] = [
    {
      label: <>{t('V3_ASIDE_MENU.LABEL_HOME')}</>,
      key: 'wall',
    },
    {
      label: <>{t('V3_ASIDE_MENU.LABEL_SPENDING_TOKENS')}</>,
      key: 'rewards',
    },
  ];

  const profileProps: AvatarProps = {
    icon: (
      <Image
        preview={false}
        src={picture || undefined}
        wrapperStyle={{ height: '100%' }}
        style={{ height: '100%', objectFit: 'cover' }}
      />
    ),
  };

  const logoProps: ButtonProps = {
    icon: <IconCliveLogo style={{ width: 35 }} />,
    style: { marginInlineEnd: sizeMS },
    type: 'link',
  };

  return <NavBar items={items} {...{ profileProps }} {...{ logoProps }} />;
});

AntHeader.displayName = 'Components_AntHeader';
